import "./BackToAbout.css";
import { Link } from "react-router-dom";

export default function BackToAbout() {
  return (
    <div className="BackToAbout app-grid">
      <div className="BackToAbout__title">About HoLa</div>

      <div className="BackToAbout__desc">
        Learn more about our school, leaders and how innovation powers our
        unique approach to education.
      </div>

      <div className="BackToAbout__action">
        <Link to="/about" className="section-cta">
          Learn More
        </Link>
      </div>
    </div>
  );
}
