import "./Admins.css";
import {
  Hero,
  PageCaption,
  ProfileItem,
  Br,
  BackToAbout,
} from "../../components";
import backgroundImage from "../../images/team-hero.jpg";
import Nav from "../../layout/Nav";
import { ROLES } from "../../api";
import { useQuery } from "@apollo/client";
import { usePageTracking } from "../../utils/usePageTracking";

export default function Admins() {
  usePageTracking({
    title: "Administrative Team",
  });

  const { data } = useQuery(ROLES, {
    variables: {
      where: {
        roleType: {
          name: "ADMINISTRATIVE_TEAM",
        },
      },
    },
  });

  // const trustees = [
  //   {
  //     name: "Nicole Cammarota",
  //     designation: "President ",
  //     email: "ncammarota@holahoboken.org ",
  //     image: "profile.png",
  //     description:
  //       "Nicole Cammarota is a Senior Counsel for the federal government. She received a B.S. in Business, J.D., and M.B.A. with Honors from Indiana University. Nicole has taught at Brooklyn Law School and New York University and is currently a member of Indiana University’s Law School Alumni board. Nicole has two young children at HoLa.",
  //   },
  //   {
  //     name: "Jon Salinas, Vice President",
  //     designation: "President ",
  //     email: "ncammarota@holahoboken.org ",
  //     image: "profile.png",
  //     description:
  //       "Nicole Cammarota is a Senior Counsel for the federal government. She received a B.S. in Business, J.D., and M.B.A. with Honors from Indiana University. Nicole has taught at Brooklyn Law School and New York University and is currently a member of Indiana University’s Law School Alumni board. Nicole has two young children at HoLa.",
  //   },
  //   {
  //     name: "Nicole Cammarota",
  //     designation: "President ",
  //     email: "ncammarota@holahoboken.org ",
  //     image: "profile.png",
  //     description:
  //       "Nicole Cammarota is a Senior Counsel for the federal government. She received a B.S. in Business, J.D., and M.B.A. with Honors from Indiana University. Nicole has taught at Brooklyn Law School and New York University and is currently a member of Indiana University’s Law School Alumni board. Nicole has two young children at HoLa.",
  //   },
  //   {
  //     name: "Nicole Cammarota",
  //     designation: "President ",
  //     email: "ncammarota@holahoboken.org ",
  //     image: "profile.png",
  //     description:
  //       "Nicole Cammarota is a Senior Counsel for the federal government. She received a B.S. in Business, J.D., and M.B.A. with Honors from Indiana University. Nicole has taught at Brooklyn Law School and New York University and is currently a member of Indiana University’s Law School Alumni board. Nicole has two young children at HoLa.",
  //   },
  //   {
  //     name: "Nicole Cammarota",
  //     designation: "President ",
  //     email: "ncammarota@holahoboken.org ",
  //     image: "profile.png",
  //     description:
  //       "Nicole Cammarota is a Senior Counsel for the federal government. She received a B.S. in Business, J.D., and M.B.A. with Honors from Indiana University. Nicole has taught at Brooklyn Law School and New York University and is currently a member of Indiana University’s Law School Alumni board. Nicole has two young children at HoLa.",
  //   },
  //   {
  //     name: "Nicole Cammarota",
  //     designation: "President ",
  //     email: "ncammarota@holahoboken.org ",
  //     image: "profile.png",
  //     description:
  //       "Nicole Cammarota is a Senior Counsel for the federal government. She received a B.S. in Business, J.D., and M.B.A. with Honors from Indiana University. Nicole has taught at Brooklyn Law School and New York University and is currently a member of Indiana University’s Law School Alumni board. Nicole has two young children at HoLa.",
  //   },
  // ];

  const roles = data?.roles || [];

  return (
    <div className="Trustees">
      <Nav />
      <Hero
        image={backgroundImage}
        text={
          <div style={{ textAlign: "center" }}>
            Administrative <Br /> Team
          </div>
        }
      />
      <PageCaption
        text={
          <span>
            <span className="bold">
              Our team share a passion for quality education, recognizes the
              value of bilingualism, and is committed to diversity in the
              classroom.
            </span>
          </span>
        }
      />

      <div
        className="Trustees__list app-grid"
        style={{ paddingBottom: "64px" }}
      >
        {roles.map((profile) => (
          <ProfileItem profile={profile} />
        ))}
      </div>

      <BackToAbout />
    </div>
  );
}
