import { gql } from "@apollo/client";

const SUBSCRIBE = gql`
  mutation subscribeMutation($email: String!) {
    subscribe(email: $email) {
      success
      error
    }
  }
`;

export default SUBSCRIBE;
