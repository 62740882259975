import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { StateProvider } from "./utils/appState";
import { InMemoryCache, ApolloProvider, gql } from "@apollo/client";
import { client } from "./utils/client";

const initialState = {
  menu: false,
};

const reducer = (state, action = {}) => {
  return {
    ...state,
    ...action,
  };
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ApolloProvider client={client}>
    <BrowserRouter>
      <React.StrictMode>
        <StateProvider initialState={initialState} reducer={reducer}>
          <App />
        </StateProvider>
      </React.StrictMode>
    </BrowserRouter>
  </ApolloProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
