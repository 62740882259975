import "./Careers.css";
import { Hero, PageCaption } from "../../components";
import backgroundImage from "../../images/careers-hero.jpg";
import Nav from "../../layout/Nav";
import { useInView } from "framer-motion";
import { useRef } from "react";
import { isMobile } from "react-device-detect";
import { usePageTracking } from "../../utils/usePageTracking";

function InvitationSection() {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true, amount: 0.2 });

  return (
    <div className="Careers__section app-grid" ref={ref}>
      <div className="Careers__section__content">
        <div
          className="section-title"
          style={{
            opacity: isInView ? 1 : 0,
            transition: "all 0.4s cubic-bezier(0.17, 0.55, 0.55, 1) 0.1s",
          }}
        >
          You are invited to apply to teach at one of New Jersey’s most
          innovative schools.
        </div>

        <div
          className="section-p"
          style={{
            opacity: isInView ? 1 : 0,
            transition: "all 0.4s cubic-bezier(0.17, 0.55, 0.55, 1) 0.2s",
          }}
        >
          By joining Hoboken Dual Language Charter School (HoLa), you’ll be at
          the forefront of the growing field of dual language education and be a
          part of a collaborative team of top educators and receive
          best-in-class professional development. HoLa is committed to
          cultivating teachers who are passionate, dedicated, and recognized for
          their excellence.
        </div>
      </div>
    </div>
  );
}

function JoinSection() {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true, amount: 0.2 });

  return (
    <div
      className="Careers__section app-grid"
      style={{ paddingBottom: "72px" }}
      ref={ref}
    >
      <div className="Careers__section__content">
        <div
          className="Careers__section__content__row"
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(12, minmax(0,1fr))",
          }}
        >
          {!isMobile && (
            <div
              className="Careers__section__content__row__column"
              style={{
                gridColumnStart: 1,
                gridColumnEnd: 6,
              }}
            >
              <img
                src={"careers-1.png"}
                style={{ maxWidth: "100%", height: "auto" }}
                alt=""
              />
            </div>
          )}

          <div
            className="Careers__section__content__row__column"
            style={{
              gridColumnStart: isMobile ? 1 : 7,
              gridColumnEnd: 13,
            }}
          >
            <div
              className="section-title"
              style={{
                opacity: isInView ? 1 : 0,
                transition: "all 0.4s cubic-bezier(0.17, 0.55, 0.55, 1) 0.1s",
              }}
            >
              Join Us{" "}
            </div>

            <div
              className="section-p"
              style={{
                opacity: isInView ? 1 : 0,
                transition: "all 0.4s cubic-bezier(0.17, 0.55, 0.55, 1) 0.2s",
              }}
            >
              Grow into the teacher you always imagined yourself to be.
            </div>

            {/* <div
              className="section-p"
              style={{
                opacity: isInView ? 1 : 0,
                transition: "all 0.4s cubic-bezier(0.17, 0.55, 0.55, 1) 0.2s",
              }}
            >
              For any question please email: school@holahoboken.org.
            </div> */}

            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <a
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  color: "white",
                  background: "#F27224",
                  height: isMobile ? "56px" : "64px",
                  paddingLeft: "48px",
                  paddingRight: "48px",
                  fontSize: isMobile ? "16px" : "20px",
                  fontWeight: "700",
                  borderRadius: "32px",
                  marginTop: isMobile ? "8px" : "24px",
                  opacity: isInView ? 1 : 0,
                  transition: "all 0.4s cubic-bezier(0.17, 0.55, 0.55, 1) 0.3s",
                  ...(isMobile ? { width: "100%" } : {}),
                }}
                href={
                  " https://www.njschooljobs.com/employerjobs/mm7f/hoboken-dual-language-charter-school"
                }
                target="_blank"
                rel="noreferrer"
              >
                Apply Today
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default function Careers() {
  usePageTracking({
    title: "Careers",
  });

  return (
    <div className="Careers">
      <Nav />
      <Hero image={backgroundImage} text={"Teach With Us"} />
      <PageCaption
        text={
          <span>
            <span className="bold">
              HoLa’s staff is 90% Hispanic and represents over 15 different
              countries throughout the Americas and Europe.
            </span>
          </span>
        }
        options={[
          {
            label: "Join Us",
            type: "external",
            to: " https://www.njschooljobs.com/employerjobs/mm7f/hoboken-dual-language-charter-school",
          },
        ]}
      />

      <InvitationSection />
      <JoinSection />
    </div>
  );
}
