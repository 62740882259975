import "./ProfileItem.css";
import { useState } from "react";
import { ReactComponent as Expand } from "../../../icons/expand.svg";
import makeFileUrl from "../../../utils/makeFileUrl";

export default function ProfileItem({ profile }) {
  const [expanded, setExpanded] = useState(false);
  return (
    <div className={"Profile__item " + (expanded ? "expanded" : "")}>
      <div className="Profile__item__header">
        <div className="Profile__item__header__content">
          <div className="Profile__item__header__info">
            <div className="Profile__item__header__name">
              {profile.name}, {profile.title}
            </div>

            <div className="Profile__item__header__email">{profile.email}</div>
          </div>

          <div
            className="Profile__item__header__expand"
            onClick={() => {
              setExpanded((prev) => !prev);
            }}
          >
            <Expand className="Profile__item__header__expand__svg" />
          </div>
        </div>
      </div>

      {expanded && (
        <div className="Profile__item__body">
          <div className="Profile__item__body__img">
            {profile.media && <img src={makeFileUrl(profile.media)} />}
          </div>

          <div className="Profile__item__body__blurb h4">{profile.blurb}</div>
        </div>
      )}
    </div>
  );
}
