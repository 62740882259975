import "./DonutChart.css";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

ChartJS.register(ArcElement, Tooltip, Legend);

export default function DonutChart({ d, colors = ["#AB1E43", "#F8F8F8"] }) {
  const data = {
    // labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
    datasets: [
      {
        data: d,
        backgroundColor: colors,
        // borderColor: [
        //   '#FB7626',
        //   '#1FB1CC',
        // ],
        // borderWidth: 1,
      },
    ],

    // options: {

    // },
  };

  const options = {
    cutout: "90%",
    // borderJoinStyle: "round",
    plugins: {
      tooltip: {
        enabled: false,
      },
      hover: { mode: null },
    },
  };

  return <Doughnut data={data} options={options} />;
}
