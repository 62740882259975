import "./Donate.css";
import { Hero, PageCaption } from "../../components";
import Nav from "../../layout/Nav";
import backgroundImage from "../../images/donate-hero.jpg";
import { isMobile } from "react-device-detect";
import { usePageTracking } from "../../utils/usePageTracking";

export default function Donate() {
  usePageTracking({
    title: "Donate",
  });

  return (
    <div className="Donate">
      <Nav />
      <Hero image={backgroundImage} text={"Give"} />
      <PageCaption
        text={
          <span>
            <span className="bold">
              Every dollar raised enhances our children’s school experience and
              supports our educators in fulfilling the school’s mission of
              delivering a world-class bilingual education.
            </span>
          </span>
        }
      />

      <div className="section app-grid">
        <div className="section-content">
          <div className="section-p">
            As a top-performing public school (top 5% in NJ), HoLa serves nearly
            15% of Hoboken's K-8 public school students, however, it receives
            the lowest per-student funding of any public school in NJ (bottom
            5%). Additionally, due to the lack of funding from the State of New
            Jersey for building and facilities costs for charter schools, HoLa
            must spend nearly 12% of its per-student funding, or $1,500 per
            student per year, on facilities alone.
          </div>

          <div className="section-p">
            Despite this, HoLa continues to deliver a world-class bilingual
            education thanks to The Friends of HoLa (FOH). FOH is a 501(c)(3)
            organization - an all-volunteer, parent-run, non-profit - created to
            benefit HoLa and bridge the financial gap. Since 2016, FOH has
            successfully raised over $1 million to support HoLa initiatives.
          </div>
        </div>
      </div>

      <div className="Donate__subheading">
        We thank you in advance for your generous donations.
      </div>

      <div className="section app-grid">
        {isMobile ? (
          <div
            className="Donate__mobile-image-wrapper"
            style={{
              gridColumnStart: 1,
              gridColumnEnd: 13,
              paddingLeft: "16px",
              paddingRight: "16px",
              overflowX: "auto",
            }}
          >
            <img src="/donate-1.png" alt="" className="Donate__image" />
          </div>
        ) : (
          <div className="section-content">
            <img src="/donate-1.png" alt="" className="Donate__image" />
          </div>
        )}
      </div>

      <div className="Donate__subheading">
        <div className="Donate__subheading__line">
          Please consider an Innovation Gift of $2,010.{" "}
        </div>
        <div className="Donate__subheading__line">
          This level recognizes the year that HoLa was founded, 2010.
        </div>
      </div>

      <div className="section app-grid">
        <div
          className="Donate__form "
          style={{ gridColumnStart: 1, gridColumnEnd: 13 }}
        >
          <iframe
            onload="window.parent.scrollTo(0,0)"
            height="1540"
            allowTransparency="true"
            allow="payment"
            frameBorder="0"
            scrolling="no"
            title="donate-iframe"
            style={{ width: "100%", border: "none" }}
            src="https://secure.lglforms.com/form_engine/s/MToPYbhZYApjKFf2vTVgnA"
          >
            <a href="https://secure.lglforms.com/form_engine/s/MToPYbhZYApjKFf2vTVgnA">
              Fill out my LGL Form!
            </a>
          </iframe>
        </div>
      </div>
    </div>
  );
}
