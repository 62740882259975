import "./App.css";
import { Layout } from "./layout";
import { AnimatePresence } from "framer-motion";

function App() {
  return (
    <AnimatePresence>
      <Layout />
    </AnimatePresence>
  );
}

export default App;
