import "./Nav.css";
import { NavLink } from "react-router-dom";
// import { motion } from "framer-motion";
import { isMobile } from "react-device-detect";
import { ReactComponent as MenuIcon } from "../../icons/menu.svg";
import { useAppState } from "../../utils/appState";

function MobileNav() {
  const [, setAppState] = useAppState();
  return (
    <div className="Nav-mobile">
      <NavLink to="/" className="Nav-mobile__brand">
        <img src="logo-white.svg" />
      </NavLink>

      <div
        className="Nav-mobile__menu-btn"
        onClick={() => {
          setAppState({ menu: true });
        }}
      >
        <MenuIcon />
      </div>
    </div>
  );
}

export default function Nav() {
  if (isMobile) {
    return <MobileNav />;
  }

  return (
    <div className="Nav">
      <NavLink to="/" className="Nav__brand">
        <img src="logo-white.svg" />
      </NavLink>

      <div className="Nav__links">
        {/* <NavLink className="Nav__links__link" to="/">
          Home
        </NavLink> */}
        <NavLink className="Nav__links__link" to="/about">
          About
        </NavLink>
        <NavLink className="Nav__links__link" to="/admissions">
          Admissions
        </NavLink>
        <NavLink className="Nav__links__link" to="/academics">
          Academics
        </NavLink>
        <NavLink className="Nav__links__link" to="/life">
          Student Life
        </NavLink>
        <NavLink className="Nav__links__link" to="/news">
          News
        </NavLink>
        <NavLink className="Nav__links__link" to="/careers">
          Careers
        </NavLink>
        {/* <NavLink className="Nav__links__link" to="/contact">
          Contact
        </NavLink> */}

        <NavLink className="Nav__links__link cta" to="/donate">
          Give
        </NavLink>
      </div>
    </div>
  );
}
