import "./Hero.css";
import { motion } from "framer-motion";

export default function Hero({ text, image }) {
  return (
    <div className="Hero" style={{ backgroundImage: `url(${image})` }}>
      <motion.div
        variants={{
          initial: {
            opacity: 0,
            y: "16px",
          },
          final: {
            opacity: 1,
            y: "0px",
            transition: {
              duration: 0.2,
              delay: 0.4,
            },
          },
        }}
        initial="initial"
        animate="final"
        className="Hero__text"
      >
        {text}
      </motion.div>
    </div>
  );
}
