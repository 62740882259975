import { gql } from "@apollo/client";

const LOTTERIES = gql`
  query lotteriesQuery($where: LotteryWhereInput!) {
    lotteries(where: $where) {
      id
      year
      opening
      deadline
      drawing
    }
  }
`;

export default LOTTERIES;
