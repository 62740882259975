import { gql } from "@apollo/client";

const POLICIES = gql`
  query policiesQuery($where: PolicyWhereInput) {
    policies(where: $where) {
      id
      name
      url
    }
  }
`;

export default POLICIES;
