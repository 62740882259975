import "./Alumni.css";
import { BackToAbout, CellMeasurer, Hero, PageCaption } from "../../components";
import backgroundImage from "../../images/alumni-hero.jpg";
import Nav from "../../layout/Nav";
import { Link } from "react-router-dom";
import { usePageTracking } from "../../utils/usePageTracking";

export default function Alumni() {
  usePageTracking({
    title: "Alumni",
  });

  return (
    <div className="Alumni">
      <Nav />
      <Hero image={backgroundImage} text={"Hola Alumni"} />
      <PageCaption
        text={
          <span>
            <span className="bold">
              HoLa graduates are admitted to some of the best public and private
              high schools in the region. We are proud of the legacy HoLa is
              building for our community and our students.
            </span>
          </span>
        }
      />

      <CellMeasurer>
        {({ squareRef, dimensions }) => (
          <div className="Alumni__high-school app-grid">
            <div className="Alumni__high-school__content" ref={squareRef}>
              <div className="section-title">HoLa Grads in High School </div>

              <div className="section-p">
                Over the years, our graduating classes have been accepted into
                top high schools in the area.
              </div>

              <ul className="Alumni__high-school__content__list">
                <li>Regis High School</li>
                <li>High Tech High School </li>
                <li>Avenues: The World School</li>
                <li>United Nations International School</li>
                <li>Dwight Englewood </li>
                <li>St. Peter’s Prep</li>
              </ul>
            </div>

            <div className="Alumni__high-school__image">
              <img
                src={"alumni-1.png"}
                style={{ height: dimensions.height, maxWidth: "100%" }}
                alt=""
              />
            </div>
          </div>
        )}
      </CellMeasurer>

      <div className="Alumni__college app-grid">
        <div className="Alumni__college__content">
          <div className="section-title">HoLa Grads in College</div>
          <div className="Alumni__college__content__lists">
            <div className="Alumni__college__content__lists__list">
              <ul>
                <li>Yale University</li>
                <li>University of Michigan</li>
                <li>Northeastern University</li>
                <li>Rutgers University</li>
                <li>Skidmore College</li>
                <li>Parsons School of Design</li>
              </ul>
            </div>

            <div className="Alumni__college__content__lists__list">
              <ul>
                <li>University of California Berkeley</li>
                <li>Emory University</li>
                <li>Stevens Institute of Technology</li>
                <li>Esade School of Business (Barcelona)</li>
                <li>and more…</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div>
        <img src={"alumni-2.png"} style={{ width: "100%" }} alt="" />
      </div>

      <CellMeasurer>
        {({ squareRef, dimensions }) => (
          <div className="Alumni__reconnect app-grid">
            <div className="Alumni__reconnect__content" ref={squareRef}>
              <div className="section-title">Reconnect with HoLa</div>
              <div className="section-p">
                We are HoLa! There are many ways for our Alumni to connect with
                their friends and our community.
              </div>
              <div className="Alumni__reconnect__content__action">
                <Link
                  to={"/donate"}
                  className="Alumni__reconnect__content__action__btn"
                >
                  Donate
                </Link>
              </div>
            </div>

            <div className="Alumni__reconnect__image">
              <img
                src={"alumni-3.png"}
                style={{ height: dimensions.height, width: "auto" }}
                alt=""
              />
            </div>
          </div>
        )}
      </CellMeasurer>

      <div className="Alumni__tiles">
        <div className="Alumni__tiles__item" style={{ background: "#1FB1CC" }}>
          <div className="Alumni__tiles__item__primary">ALUMNI CONNECT</div>

          <div className="Alumni__tiles__item__secondary">
            Reconnect with HoLa and get on the Alumni newsletter.
          </div>

          <a
            href={
              "https://docs.google.com/forms/d/1ih6UwnVxJoAsM-d_f_3YLY52yREQX4wTl_tS9S5NDQE/edit"
            }
            target={"_blank"}
            className="Alumni__tiles__item__link"
            rel="noreferrer"
          >
            Sign Up Now
          </a>
        </div>

        <div className="Alumni__tiles__item" style={{ background: "#F27224" }}>
          <div className="Alumni__tiles__item__primary">BE A CLASS REP</div>

          <div className="Alumni__tiles__item__secondary">
            Sign up to serve as a class representative.
          </div>

          <a
            href={
              "https://docs.google.com/forms/d/1ih6UwnVxJoAsM-d_f_3YLY52yREQX4wTl_tS9S5NDQE/edit"
            }
            target={"_blank"}
            className="Alumni__tiles__item__link"
            rel="noreferrer"
          >
            Sign Up Now
          </a>
        </div>

        <div className="Alumni__tiles__item" style={{ background: "#AB1E43" }}>
          <div className="Alumni__tiles__item__primary">BE AN AMBASSADOR</div>

          <div className="Alumni__tiles__item__secondary">
            Be a Young Alumni College Ambassador and support our high school and
            college-bound HoLa grads!
          </div>

          <a
            href={
              "https://docs.google.com/forms/d/1ih6UwnVxJoAsM-d_f_3YLY52yREQX4wTl_tS9S5NDQE/edit"
            }
            target={"_blank"}
            className="Alumni__tiles__item__link"
            rel="noreferrer"
          >
            Sign Up Now
          </a>
        </div>
      </div>

      <BackToAbout />
    </div>
  );
}
