import "./Footer.css";
import { ReactComponent as StarIcon } from "../../icons/star.svg";
import { ReactComponent as LinkedInIcon } from "../../icons/linkedin.svg";
import { ReactComponent as FacebookIcon } from "../../icons/facebook.svg";
import { ReactComponent as YouTubeIcon } from "../../icons/yt.svg";
import { ReactComponent as TwitterIcon } from "../../icons/twitter.svg";
import { ReactComponent as InstagramIcon } from "../../icons/instagram.svg";
import { isMobile } from "react-device-detect";
import { useQuery } from "@apollo/client";
import { POLICIES } from "../../api";

export default function Footer() {
  const { error, data } = useQuery(POLICIES, {
    variables: {
      where: {
        showInFooter: true,
      },
    },
  });

  if (error) {
    console.log(error);
  }

  const policies = data?.policies || [];

  return (
    <div className="Footer">
      <div className="Footer__title">
        <div className="Footer__title__logo">
          <img src={"logo-white.svg"} />
        </div>

        <div className="Footer__title__brand">
          <div className="Footer__title__brand__primary">
            Hoboken Dual Language Charter School
          </div>

          <div className="Footer__title__brand__secondary">
            La escuela charter de dos idiomas
          </div>
        </div>
      </div>

      <div className="Footer__content">
        <div className="Footer__content__info">
          <div className="Footer__content__info__contacts">
            <div className="Footer__content__info__contacts__item">
              <div className="Footer__content__info__contacts__item__icon">
                <StarIcon
                  style={isMobile ? { height: "10px" } : { height: "14px" }}
                />
              </div>

              <div className="Footer__content__info__contacts__item__text">
                Elementary School: 123 Jefferson St., Hoboken, NJ 07030
              </div>
            </div>

            <div className="Footer__content__info__contacts__item">
              <div className="Footer__content__info__contacts__item__icon">
                <StarIcon
                  style={isMobile ? { height: "10px" } : { height: "14px" }}
                />
              </div>

              <div className="Footer__content__info__contacts__item__text">
                Middle School: 301 Garden St., Hoboken, NJ 07030
              </div>
            </div>
            <div className="Footer__content__info__contacts__item">
              <div className="Footer__content__info__contacts__item__icon">
                <StarIcon
                  style={isMobile ? { height: "10px" } : { height: "14px" }}
                />
              </div>

              <div className="Footer__content__info__contacts__item__text">
                Tel: (201) 427-1458 | Email: school@holahoboken.org | Fax: (201)
                706-4491
              </div>
            </div>
          </div>

          {!isMobile && (
            <div className="Footer__content__info__copyright">
              <div className="Footer__content__info__copyright__line">
                {policies.map((policy, i) => (
                  <>
                    <a href={policy.url} target="_blank" rel="noreferrer">
                      {policy.name}
                    </a>
                    {i < policies.length - 1 ? " | " : ""}
                  </>
                ))}
              </div>
              {/* <div className="Footer__content__info__copyright__line">
                <a
                  href="https://hola-filestore.nyc3.cdn.digitaloceanspaces.com/public/HIB%20Policy%20+%20Forms.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  HIB Policy + Forms
                </a>{" "}
                |{" "}
                <a
                  href="https://docs.google.com/spreadsheets/d/1OMQYU6p04Di0pEx2C3uhJMKrQdvjKCrT/edit#gid=1133563946"
                  target="_blank"
                  rel="noreferrer"
                >
                  NJDOE Gifted and Talented Information
                </a>{" "}
                |{" "}
                <a
                  href="https://hola-filestore.nyc3.cdn.digitaloceanspaces.com/public/HoLa-School-Health-Related-Closure-Preparedness-Plan-2022-23%20(1).pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  Health-Related Closure Preparedness Plan
                </a>
              </div> */}

              {/* <div className="Footer__content__info__copyright__line">
                <a
                  href="https://hola-filestore.nyc3.cdn.digitaloceanspaces.com/public/Hoboken-Water-71020-2020%20(1).pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  Lead in Water Testing Elementary School Results
                </a>{" "}
                |{" "}
                <a
                  href="https://hola-filestore.nyc3.cdn.digitaloceanspaces.com/public/77058-Rue-FINAL-Report%20(1).pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  Lead in Water Testing Middle School Results
                </a>{" "}
                |{" "}
                <a
                  href="https://hola-filestore.nyc3.cdn.digitaloceanspaces.com/public/HoLa_2024%20Plan%20for%20Safe%20Return%20to%20In-Person%20Instruction_ESSER_ARP.docx"
                  target="_blank"
                  rel="noreferrer"
                >
                  LEA Plan for Safe Return
                </a>{" "}
              </div> */}

              <div
                className="Footer__content__info__copyright__line"
                style={{ fontSize: "10px", marginTop: "8px" }}
              >
                ©️ Hoboken Dual Language Charter School. HoLa is a registered
                trademark of Hoboken Dual Language Charter School.{" "}
              </div>
            </div>
          )}
        </div>

        <div className="Footer__content__socials">
          <a
            href={"https://www.linkedin.com/company/hola-hoboken/"}
            target={"_blank"}
            className="Footer__content__socials__item"
          >
            <LinkedInIcon style={{ height: "32px" }} />
          </a>

          <a
            href={"https://twitter.com/holahoboken"}
            target={"_blank"}
            className="Footer__content__socials__item"
          >
            <TwitterIcon style={{ height: "32px" }} />
          </a>

          <a
            href={"https://www.facebook.com/HoLaHoboken.org"}
            target={"_blank"}
            className="Footer__content__socials__item"
          >
            <FacebookIcon style={{ height: "32px" }} />
          </a>

          <a
            href={"https://www.instagram.com/holahoboken/"}
            target={"_blank"}
            className="Footer__content__socials__item"
          >
            <InstagramIcon style={{ height: "32px" }} />
          </a>

          <a
            href={"https://www.youtube.com/channel/UCyJ1ICeJJe3SywbAjFULF6A"}
            target={"_blank"}
            className="Footer__content__socials__item"
          >
            <YouTubeIcon style={{ height: "32px" }} />
          </a>
        </div>
      </div>

      {isMobile && (
        <div
          className="Footer__content__info__copyright"
          style={{ textAlign: "left" }}
        >
          <div
            className="Footer__content__info__copyright__line"
            style={{ marginBottom: "12px" }}
          >
            {policies.map((policy, i) => (
              <>
                <a href={policy.url} target="_blank" rel="noreferrer">
                  {policy.name}
                </a>
                {i < policies.length - 1 ? " | " : ""}
              </>
            ))}
          </div>

          <div className="Footer__content__info__copyright__line"></div>
          <div className="Footer__content__info__copyright__line">
            ©️ Hoboken Dual Language Charter School. HoLa is a registered
            trademark of Hoboken Dual Language Charter School.
          </div>
        </div>
      )}
    </div>
  );
}
