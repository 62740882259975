import "./News.css";
import { Hero, PageCaption } from "../../components";
import backgroundImage from "../../images/news-hero.jpg";
import Nav from "../../layout/Nav";
import { useQuery } from "@apollo/client";
import { PRESS_ITEMS } from "../../api";
import moment from "moment";
import makeFileUrl from "../../utils/makeFileUrl";
import { usePageTracking } from "../../utils/usePageTracking";

export default function News() {
  usePageTracking({
    title: "News",
  });

  const { loading, data } = useQuery(PRESS_ITEMS);

  const pressItems = data?.pressItems || [];
  return (
    <div className="News">
      <Nav />
      <Hero image={backgroundImage} text={"In The News"} />
      <PageCaption
        text={
          <span>
            <span className="bold">Check out the latest news about HoLa.</span>
          </span>
        }
      />

      <div className="News__list-wrapper app-grid">
        <div className="News__list">
          {pressItems.map((pressItem) => (
            <div className="News__list__item">
              <div className="News__list__item__image">
                {pressItem.media ? (
                  <img src={makeFileUrl(pressItem.media)} alt="" />
                ) : (
                  <img src={"/press-placeholder.png"} alt="" />
                )}
              </div>

              <div className="News__list__item__content">
                {/* <div className="News__list__item__content__length">
                  {pressItem.timeToRead} min read
                </div> */}

                <a
                  href={pressItem.url}
                  target={"_blank"}
                  className="News__list__item__content__title"
                  rel="noreferrer"
                >
                  {pressItem.title}
                </a>

                <div className="News__list__item__content__meta">
                  {moment(pressItem.publishedAt || pressItem.createdAt).format(
                    "MMM DD, YYYY"
                  )}{" "}
                  | by {pressItem.author}
                </div>
              </div>
            </div>
          ))}

          {!loading && !pressItems.length && (
            <div className="News__list__no-data">Nothing here yet.</div>
          )}
        </div>
      </div>
    </div>
  );
}
