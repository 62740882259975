import "./Menu.css";
import { ReactComponent as CloseIcon } from "../../icons/close.svg";
import { useAppState } from "../../utils/appState";
import { NavLink } from "react-router-dom";

export default function Menu() {
  const [, setAppState] = useAppState();
  return (
    <div className="Menu">
      <div
        className="Menu__dismiss"
        onClick={() => {
          setAppState({ menu: false });
        }}
      >
        <CloseIcon />
      </div>
      <div className="Menu__logo">
        <img src={"logo-white.svg"} />
      </div>
      <div className="Menu__links">
        <NavLink
          onClick={() => {
            setAppState({ menu: false });
          }}
          to="/"
          className="Menu__links__link"
        >
          Home
        </NavLink>

        <NavLink
          onClick={() => {
            setAppState({ menu: false });
          }}
          to={"/about"}
          className="Menu__links__link"
        >
          About Us
        </NavLink>

        <NavLink
          onClick={() => {
            setAppState({ menu: false });
          }}
          to={"/admissions"}
          className="Menu__links__link"
        >
          Admissions
        </NavLink>
        <NavLink
          onClick={() => {
            setAppState({ menu: false });
          }}
          to={"/academics"}
          className="Menu__links__link"
        >
          Academics
        </NavLink>
        <NavLink
          onClick={() => {
            setAppState({ menu: false });
          }}
          to={"/life"}
          className="Menu__links__link"
        >
          Student Life
        </NavLink>
        <NavLink
          onClick={() => {
            setAppState({ menu: false });
          }}
          to={"/news"}
          className="Menu__links__link"
        >
          News
        </NavLink>
        <NavLink
          onClick={() => {
            setAppState({ menu: false });
          }}
          to={"/careers"}
          className="Menu__links__link"
        >
          Careers
        </NavLink>
        {/* <NavLink
          onClick={() => {
            setAppState({ menu: false });
          }}
          to={"/contact"}
          className="Menu__links__link"
        >
          Contact
        </NavLink> */}

        <NavLink
          onClick={() => {
            setAppState({ menu: false });
          }}
          to={"/donate"}
          className="Menu__links__link-btn"
        >
          Give
        </NavLink>
      </div>
    </div>
  );
}
