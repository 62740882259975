import "./Trustees.css";
import { BackToAbout, Hero, PageCaption, ProfileItem } from "../../components";
import backgroundImage from "../../images/trustees-hero.jpg";
import Nav from "../../layout/Nav";
import { BOARD_MEETINGS, ROLES } from "../../api";
import { useQuery } from "@apollo/client";
import { usePageTracking } from "../../utils/usePageTracking";
import { isMobile } from "react-device-detect";
import moment from "moment";

function BoardMeetings() {
  const { data } = useQuery(BOARD_MEETINGS, {
    variables: {},
  });

  const boardMeetings = data?.boardMeetings || [];

  console.log(boardMeetings);

  return (
    <ul className="section-ul">
      {boardMeetings.map((x) => (
        <li>
          {moment(x.date).format("dddd MMMM DD, YYYY")} {x.description}{" "}
          {!!x.url && "("}
          {!!x.url && (
            <a
              href={x.url}
              target="_blank"
              rel="noopener noreferrer"
              className="board-meeting-link"
            >
              Link
            </a>
          )}
          {!!x.url && ")"}
        </li>
      ))}
    </ul>
  );
}

export default function Trustees() {
  usePageTracking({
    title: "Trustees",
  });

  const { data } = useQuery(ROLES, {
    variables: {
      where: {
        roleType: {
          name: "TRUSTEE",
        },
      },
    },
  });

  // const trustees = [
  //   {
  //     name: "Nicole Cammarota",
  //     designation: "President ",
  //     email: "ncammarota@holahoboken.org ",
  //     image: "profile.png",
  //     description:
  //       "Nicole Cammarota is a Senior Counsel for the federal government. She received a B.S. in Business, J.D., and M.B.A. with Honors from Indiana University. Nicole has taught at Brooklyn Law School and New York University and is currently a member of Indiana University’s Law School Alumni board. Nicole has two young children at HoLa.",
  //   },
  //   {
  //     name: "Jon Salinas, Vice President",
  //     designation: "President ",
  //     email: "ncammarota@holahoboken.org ",
  //     image: "profile.png",
  //     description:
  //       "Nicole Cammarota is a Senior Counsel for the federal government. She received a B.S. in Business, J.D., and M.B.A. with Honors from Indiana University. Nicole has taught at Brooklyn Law School and New York University and is currently a member of Indiana University’s Law School Alumni board. Nicole has two young children at HoLa.",
  //   },
  //   {
  //     name: "Nicole Cammarota",
  //     designation: "President ",
  //     email: "ncammarota@holahoboken.org ",
  //     image: "profile.png",
  //     description:
  //       "Nicole Cammarota is a Senior Counsel for the federal government. She received a B.S. in Business, J.D., and M.B.A. with Honors from Indiana University. Nicole has taught at Brooklyn Law School and New York University and is currently a member of Indiana University’s Law School Alumni board. Nicole has two young children at HoLa.",
  //   },
  //   {
  //     name: "Nicole Cammarota",
  //     designation: "President ",
  //     email: "ncammarota@holahoboken.org ",
  //     image: "profile.png",
  //     description:
  //       "Nicole Cammarota is a Senior Counsel for the federal government. She received a B.S. in Business, J.D., and M.B.A. with Honors from Indiana University. Nicole has taught at Brooklyn Law School and New York University and is currently a member of Indiana University’s Law School Alumni board. Nicole has two young children at HoLa.",
  //   },
  //   {
  //     name: "Nicole Cammarota",
  //     designation: "President ",
  //     email: "ncammarota@holahoboken.org ",
  //     image: "profile.png",
  //     description:
  //       "Nicole Cammarota is a Senior Counsel for the federal government. She received a B.S. in Business, J.D., and M.B.A. with Honors from Indiana University. Nicole has taught at Brooklyn Law School and New York University and is currently a member of Indiana University’s Law School Alumni board. Nicole has two young children at HoLa.",
  //   },
  //   {
  //     name: "Nicole Cammarota",
  //     designation: "President ",
  //     email: "ncammarota@holahoboken.org ",
  //     image: "profile.png",
  //     description:
  //       "Nicole Cammarota is a Senior Counsel for the federal government. She received a B.S. in Business, J.D., and M.B.A. with Honors from Indiana University. Nicole has taught at Brooklyn Law School and New York University and is currently a member of Indiana University’s Law School Alumni board. Nicole has two young children at HoLa.",
  //   },
  // ];

  const roles = data?.roles || [];

  return (
    <div className="Trustees">
      <Nav />
      <Hero image={backgroundImage} text={"Board of Trustees"} />
      <PageCaption
        text={
          <span>
            <span className="bold">
              Our Board of Trustees supports HoLa's leadership with legal,
              financial and strategic guidance, and holds our school accountable
              for results.
            </span>
          </span>
        }
      />

      <div className="Trustees__list app-grid">
        {roles.map((profile) => (
          <ProfileItem profile={profile} />
        ))}
      </div>

      <div className="section app-grid">
        <div className="section-content">
          <div className="section-title">HoLa Advisory Committee</div>

          <div className="section-p">
            The HoLa Advisory Committee serves the school by lending their time
            and professional expertise to help us grow the next generation of
            global citizens.
          </div>

          <ul className="section-ul">
            <li>
              Jennifer Austin Ph.D, Center for Migration and the Global City,
              Spanish and Portuguese Studies, Rutgers University
            </li>
            <li>
              Cindy Chin, Ed.D., Vice President for Enrollment Management,
              Stevens Institute of Technology
            </li>
            <li>Barbara Martinez, Chief Media Officer, Uncommon Schools</li>
            <li>
              Jennifer Baxi, Director of Diversity, Equity, and Inclusion,
              Planned Parenthood Federation of America
            </li>
            <li>
              Carlos Lejnieks, CEO of Big Brothers Big Sisters of Essex, Hudson
              & Union Counties (Newark, NJ)
            </li>
          </ul>
        </div>
      </div>

      <div className="section app-grid">
        <div className="section-content">
          <div className="section-title">Public Notice</div>

          <div className="section-p">
            Revised Public Meeting Schedule HOBOKEN DUAL LANGUAGE CHARTER
            SCHOOL. In compliance with the Open Public Meetings Act
            (N.J.S.A.10:4-6et.seq.), this is notice is that the Hoboken Dual
            Language Charter School Board of Trustees approved the following
            meetings to be held the dates below. The meetings will be called to
            order at 7:15 p.m. at 301 Garden Street, Hoboken, New Jersey 07030.
            Formal action will be taken.
          </div>

          {/* <ul className="section-ul">
            <li>Monday September 11, 2023 Regular Meeting</li>
            <li>Tuesday October 10, 2023 Regular Meeting</li>
            <li>Tuesday November 14, 2023 Regular Meeting</li>
            <li>Tuesday December 12, 2023 Regular Meeting</li>
            <li>Tuesday January 9, 2024 Regular Meeting</li>
            <li>Tuesday February 13, 2024 Regular Meeting</li>
            <li>Tuesday March 12, 2024 Regular Meeting/Budget Meeting</li>
            <li>Tuesday April 9, 2024 Regular Meeting</li>
            <li>Tuesday May 7, 2024 Regular Meeting</li>
            <li>Tuesday June 4, 2024 Regular Meeting</li>
          </ul> */}

          <BoardMeetings />

          <div className="section-cta-wrapper">
            <a
              href={
                "https://www.dropbox.com/sh/8d9qjuf22aka9hs/AAC-PT-Yk4iO1DJnouaa327Ca?dl=0"
              }
              target="_blank"
              className="section-cta"
              rel="noreferrer"
            >
              Board Minutes
            </a>
          </div>
        </div>
      </div>

      <div className="section app-grid">
        <div className="section-content">
          <div className="section-title">Annual Report</div>

          <div className="section-p">
            Each year, HoLa is required to submit an Annual Report to the New
            Jersey Department of Education, indicating progress on academic
            measures and mission-specific goals. Click below to view our most
            recent Annual Report.
          </div>

          <div className="section-cta-wrapper">
            <a
              href={
                "https://hola-filestore.nyc3.cdn.digitaloceanspaces.com/public/HoLa%20Annual%20Report%202022-2023.pdf"
              }
              target={"_blank"}
              className="section-cta"
              rel="noreferrer"
            >
              Annual Report
            </a>
          </div>
        </div>
      </div>

      <div className="section app-grid" style={{ marginBottom: "48px" }}>
        <div className="section-content">
          <div className="section-title">OPRA Requests</div>

          <div className="section-p">
            To request public records, in accordance with the Open Public
            Records Act,
            {!isMobile && <br />} please contact the school at{" "}
            <span className="bold" style={{ fontWeight: "700" }}>
              opra@holahoboken.org
            </span>{" "}
            .{" "}
            <div style={{ marginTop: "24px" }}>
              <a
                href={
                  "https://hola-filestore.nyc3.cdn.digitaloceanspaces.com/public/OPRA.pdf"
                }
                target={"_blank"}
                className="bold"
                style={{ color: "var(--black", fontWeight: "700" }}
                rel="noreferrer"
              >
                Click here
              </a>{" "}
              to download the OPRA request form.
            </div>
          </div>
        </div>
      </div>

      {/* <div className="PageCaption app-grid" style={{ background: "white" }}>
        <div className="PageCaption__content">
          <div className="PageCaption__text">
            <div
              style={{
                fontWeight: "700",
                fontSize: "32px",
              }}
            >
              OPRA Requests
            </div>
            <div>
              To request public records, in accordance with the Open Public
              <br />
              Records Act, please contact the school at{" "}
              <span className="bold">opra@holahoboken.org</span>
            </div>

            <div>
              <a
                href={
                  "https://hola-filestore.nyc3.cdn.digitaloceanspaces.com/public/OPRA.pdf"
                }
                target={"_blank"}
                className="bold"
                style={{ color: "var(--black" }}
                rel="noreferrer"
              >
                Click here
              </a>{" "}
              to download the OPRA request form.
            </div>
          </div>
        </div>
      </div> */}

      <BackToAbout />
    </div>
  );
}
