import "./CellMeasurer.css";
import { useRef } from "react";
import useElementDimensions from "../../../utils/useElementDimensions";

export default function CellMeasurer({ children }) {
  const squareRef = useRef();
  const dimensions = useElementDimensions(squareRef);

  return children({ squareRef, dimensions });
}
