import { gql } from "@apollo/client";

const CREATE_ENQUIRY = gql`
  mutation createEnquiryMutation($data: EnquiryCreateInput!) {
    createEnquiry(data: $data) {
      id
    }
  }
`;

export default CREATE_ENQUIRY;
