import { gql } from "@apollo/client";

const CREATE_APPLICATION = gql`
  mutation createAppMutation($data: ApplicationCreateInput!) {
    createApplication(data: $data) {
      id
    }
  }
`;

export default CREATE_APPLICATION;
