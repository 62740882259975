import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export const usePageTracking = ({
  title = "Hola - Hoboken Dual Language Charter School",
}) => {
  const location = useLocation();
  console.log(location);

  useEffect(() => {
    // console.log("Sending page view:", title);
    window.gtag("event", "page_view", {
      page_title: title,
      page_path: location.pathname + location.search + location.hash,
    });
  }, [location]);
};
