import "./About.css";
import { Hero, PageCaption } from "../../components";
import Nav from "../../layout/Nav";
import backgroundImage from "../../images/about-hero.jpg";
import { useRef, useState, forwardRef } from "react";
import {
  useInView,
  useScroll,
  useTransform,
  useMotionValueEvent,
} from "framer-motion";
import { isMobile } from "react-device-detect";
import { Link } from "react-router-dom";
import { usePageTracking } from "../../utils/usePageTracking";
import { useQuery } from "@apollo/client";
import { POLICIES } from "../../api";

function PoliciesSection() {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true, amount: 0.2 });
  const { error, data } = useQuery(POLICIES);

  if (error) {
    console.log(error);
  }

  // if (loading || !data) {
  //   return <div>Loading...</div>;
  // }

  const policies = data?.policies || [];

  return (
    <div
      className="Academics__section app-grid"
      style={{ paddingBottom: "48px" }}
      ref={ref}
    >
      <div
        className="Academics__section__content "
        style={{
          gridColumnStart: 2,
          gridColumnEnd: isMobile ? 12 : 13,
        }}
      >
        <div
          className="Academics__section__content__row "
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(12, minmax(0,1fr))",
          }}
        >
          <div
            className="section-title"
            style={{
              gridColumnStart: 1,
              gridColumnEnd: 13,
              opacity: isInView ? 1 : 0,
              transition: "all 0.4s cubic-bezier(0.17, 0.55, 0.55, 1) 0.1s",
            }}
          >
            School Plans & Policies
          </div>
          {!isMobile && (
            <div
              className="Academics__section__content__row__column "
              style={{
                gridColumnStart: 8,
                gridColumnEnd: 13,
              }}
            >
              <img
                src={"academics-5.png"}
                style={{ maxWidth: "100%", height: "auto" }}
                alt=""
              />
            </div>
          )}

          <div
            className="Academics__section__content__row__column "
            style={{
              gridColumnStart: 1,
              gridColumnEnd: isMobile ? 13 : 7,
            }}
          >
            <div
              className="section-p"
              style={{
                paddingRight: isMobile ? "0" : "64px",
                marginBottom: isMobile ? "24px" : "56px",
                opacity: isInView ? 1 : 0,
                transition: "all 0.4s cubic-bezier(0.17, 0.55, 0.55, 1) 0.2s",
              }}
            >
              {policies.map((policy) => (
                <a
                  className="policy-link"
                  href={policy.url}
                  target={"_blank"}
                  rel="noreferrer"
                >
                  {policy.name}
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function AboutDescription() {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  // console.log(linksIsInView);

  return (
    <div className="section app-grid">
      <div className="section-content" ref={ref}>
        <div
          className="section-p"
          style={{
            opacity: isInView ? 1 : 0,
            transition: "all 0.4s cubic-bezier(0.17, 0.55, 0.55, 1) 0.1s",
          }}
        >
          We are HoLa! Founded in 2010, Hoboken Dual Language Charter School
          (HoLa) is the first and only free public Spanish + English Dual
          Language Charter School in New Jersey. Each year HoLa educates 420+
          children from Kindergarten to 8th grade through an immersive
          Spanish/English dual language approach.
        </div>

        <div
          className="section-p"
          style={{
            opacity: isInView ? 1 : 0,
            transition: "all 0.4s cubic-bezier(0.17, 0.55, 0.55, 1) 0.1s",
          }}
        >
          HoLa is a US News & World Report top New Jersey Elementary & Middle
          School (Private & Public). HoLa is the first Middle School in the
          state to have its curriculum approved for the College Board’s AP exam
          program, enabling students to take the Spanish AP exam in 8th grade to
          qualify for college credits. HoLa is designated as a Dual Language
          Model Program for the state of New Jersey.
        </div>

        <div
          className="section-p"
          style={{
            opacity: isInView ? 1 : 0,
            transition: "all 0.4s cubic-bezier(0.17, 0.55, 0.55, 1) 0.1s",
          }}
        >
          HoLa’s mission is to develop our students into bilingual, biliterate,
          and culturally aware global citizens who are highly engaged
          contributors to their communities. A diverse faculty is critical to
          achieving our mission - HoLa's staff is 90% Hispanic and represents
          over 15 different countries throughout the Americas and Europe.
        </div>

        <div
          className="section-p"
          style={{
            opacity: isInView ? 1 : 0,
            transition: "all 0.4s cubic-bezier(0.17, 0.55, 0.55, 1) 0.1s",
          }}
        >
          HoLa graduates attend the top public and private high schools and
          colleges in the U.S. and abroad. We are HoLa!
        </div>
      </div>
    </div>
  );
}

function HomeSubscribe() {
  // const [email, setEmail] = useState("");
  // const [success, setSuccess] = useState(false);
  // const [mutation, { loading }] = useMutation(SUBSCRIBE, {
  //   update: (cache, { data: { subscribe } }) => {
  //     if (subscribe.success) {
  //       setEmail("");
  //       setSuccess(true);
  //     }
  //   },
  // });
  return (
    <div className="Home__subscribe app-grid">
      <div className="Home__subscribe__title">
        Learn more about HoLa. Subscribe to our newsletter.
      </div>

      <div className="Home__subscribe__action">
        <a
          href={
            "https://docs.google.com/forms/d/e/1FAIpQLSf3V8SjC30DPvMFyFovjw5jYcGomCQ4oBvyZqOWsWfVPPHP4w/viewform?usp=sf_link"
          }
          target="_blank"
          rel="noreferrer"
          className="section-cta"
        >
          Subscribe now
        </a>
      </div>

      {/* {success && (
        <div className="Home__subscribe__success">
          You have been added to the subscriber's list!
        </div>
      )} */}
    </div>
  );
}

const LastTimelineItem = forwardRef((props, ref) => {
  const isInView = useInView(ref, { amount: "all" });

  return (
    <div
      className={"Home__timeline__items__item " + (isInView ? "black" : "")}
      ref={ref}
    >
      <div className="Home__timeline__items__item__year">{props.year}</div>

      <div className="Home__timeline__items__item__progress">
        {/* <div
            className="Home__timeline__items__item__progress__bar"
            style={{ height: barHeight }}
          ></div> */}
        <div className="Home__timeline__items__item__progress__bullet"></div>
      </div>

      <div className="Home__timeline__items__item__text">{props.text}</div>
    </div>
  );
});

function HomeTimelineItem({ year, text }) {
  const ref = useRef(null);
  const isInView = useInView(ref, { amount: isMobile ? 0.5 : "all" });

  return (
    <div
      className={"Home__timeline__items__item " + (isInView ? "black" : "")}
      ref={ref}
    >
      <div className="Home__timeline__items__item__year">{year}</div>

      <div className="Home__timeline__items__item__progress">
        {/* <div
            className="Home__timeline__items__item__progress__bar"
            style={{ height: barHeight }}
          ></div> */}
        <div className="Home__timeline__items__item__progress__bullet"></div>
      </div>

      <div className="Home__timeline__items__item__text">{text}</div>
    </div>
  );
}

function HomeTimeline({ scrollContainer }) {
  const [barHeight, setBarHeight] = useState(0);
  const ref = useRef();
  const listRef = useRef();
  const lastItemRef = useRef();
  const { scrollYProgress } = useScroll({
    target: ref,
    container: scrollContainer,
    offset: ["start end", "end end"],
    layoutEffect: false,
  });

  const progress = useTransform(scrollYProgress, [0, 0.15, 1], [0, 0, 1]);

  // const imageOpacity = useTransform(
  //   scrollYProgress,
  //   [0, 0.2, 0.3, 0.5, 0.6],
  //   [1, 0, 1, 0, 1]
  // );

  useMotionValueEvent(progress, "change", (latest) => {
    // console.log("Progress: ", latest);
    const listHeight = listRef?.current?.clientHeight || 0;
    const lastItemHeight = lastItemRef?.current?.clientHeight || 0;
    // console.log("List height:", listHeight);
    const maxBarHeight = listHeight - lastItemHeight - 64;
    const barHeight = latest * maxBarHeight;
    setBarHeight(barHeight);
  });

  return (
    <div className="Home__timeline app-grid" ref={ref}>
      <div className="Home__timeline__title">
        <div className="Home__timeline__title__primary">
          Innovation is a part of HoLa's DNA
        </div>

        <div className="Home__timeline__title__secondary">
          It has guided our unique approach to education.{" "}
        </div>
      </div>

      <div className="Home__timeline__items" ref={listRef}>
        <div className="Home__timeline__items__item black">
          <div className="Home__timeline__items__item__year">2009</div>

          <div className="Home__timeline__items__item__progress">
            <div
              className="Home__timeline__items__item__progress__bar"
              style={{ height: barHeight }}
            ></div>
            <div className="Home__timeline__items__item__progress__bullet"></div>
          </div>

          <div className="Home__timeline__items__item__text">
            HoLa's charter was approved by NJDOE. Our charter is based on
            research demonstrating that students in dual language immersion
            classrooms thrive academically - excelling in cognitive development,
            flexible thinking and problem solving.
          </div>
        </div>

        <HomeTimelineItem
          year={"2010"}
          text={
            "HoLa opened its doors for kindergarten through second grade as the first and only Spanish-English full immersion dual-language public school in New Jersey."
          }
        />

        <HomeTimelineItem
          year={"2014"}
          text={
            "HoLa was designated as a Dual Language Model Program by the NJDOE - the only charter school in NJ to receive this honor. HoLa has held this distinction annually and serves as a resource to educators from all over the US."
          }
        />

        <HomeTimelineItem
          year={"2015"}
          text={
            "HoLa was the first charter school in NJ to implement a weighted lottery system in favor of low-income families."
          }
        />

        <HomeTimelineItem
          year={"2017"}
          text={"HoLa graduated its first 8th grade class."}
        />

        <HomeTimelineItem
          year={"2018"}
          text={
            "HoLa expanded its weighted lottery to include English Language Learners and increase the weight for low-income applicants to triple the weight."
          }
        />

        <HomeTimelineItem
          year={"2019"}
          text={
            "HoLa was the first school in NJ to establish a Visiting Teacher program with the Spanish consulate."
          }
        />

        <HomeTimelineItem
          year={"2021"}
          text={
            "Hola was recognized by US News and World Report as one of the Best Elementary and Middle Schools in NJ."
          }
        />

        <HomeTimelineItem
          year={"2022"}
          text={
            "The College Board approved HoLa’s Middle School Spanish Language Arts curriculum as an AP program. 80% of 8th grade students passed the AP Spanish Language and Culture exam, receiving college credit. "
          }
        />

        <HomeTimelineItem
          year={"2022"}
          text={
            "HoLa participated in the NJDOE pilot administration of the Pathway to Biliteracy assessment for 8th graders. 100% of HoLa’s 8th graders who had attended the school for at least two years received the designation on their diplomas."
          }
        />

        <HomeTimelineItem
          year={"2023"}
          text={
            "HoLa was the first public charter to receive the School Leader Award from the New Jersey School Boards Association (NJSBA)."
          }
        />

        <LastTimelineItem
          ref={lastItemRef}
          year={"2024"}
          text={
            "The Spanish Consulate designated HoLa as an International Spanish Academy (ISA) - the first school in the state of New Jersey and the only public charter school in the entire country to receive this honor."
          }
        />

        {/* <div className="Home__timeline__items__item" ref={lastItemRef}>
            <div className="Home__timeline__items__item__year">2022</div>
  
            <div className="Home__timeline__items__item__progress">
              <div className="Home__timeline__items__item__progress__bullet"></div>
  
              
            </div>
  
            <div className="Home__timeline__items__item__text">
              HoLa participated in the NJDOE pilot administration of the Pathway
              to Biliteracy assessment for 8th graders. 100% of HoLa’s 8th graders
              who had attended the school for at least two years received the
              designation on their diplomas.
            </div>
          </div> */}
      </div>
    </div>
  );
}

function AboutGroup({ title, content, btn, image, link, background }) {
  return (
    <div className="About__section app-grid" style={{ background }}>
      <div className="About__section__content">
        <div className="section-title">{title}</div>
        <div className="section-p">{content}</div>
        <div className="About__section__content__cta">
          <Link to={link} className="section-cta">
            {btn}
          </Link>
        </div>
      </div>

      <div className="About__section__image">
        <img src={image} alt="" />
      </div>
    </div>
  );
}

export default function About({ scrollContainer }) {
  usePageTracking({
    title: "About",
  });

  return (
    <div className="About">
      <Nav />
      <Hero image={backgroundImage} text={"About Us"} />
      <PageCaption
        text={
          <span>
            <span className="bold">
              Learn more about our school, leaders and how innovation powers our
              unique approach to education.
            </span>
          </span>
        }
      />

      <AboutDescription />

      <HomeTimeline scrollContainer={scrollContainer} />
      <AboutGroup
        title={"The HoLa Administrative Team"}
        content={
          "Our team shares a passion for quality education, recognizes the value of bilingualism, and is committed to diversity in the classroom."
        }
        btn={"Meet the team"}
        image={"team-preview.jpg"}
        link={"/admins"}
        background={"#f8f8f8"}
      />
      <AboutGroup
        title={"Board of Trustees"}
        content={
          "Our Board of Trustees supports HoLa's leadership with legal, financial and strategic guidance, and holds our school accountable for results."
        }
        btn={"Meet the team"}
        image={"trustees-preview.png"}
        link={"/trustees"}
        background={"#ffffff"}
      />
      <AboutGroup
        title={"The Friends of HoLa"}
        content={
          "A 501(c)(3) organization, FOH is an all-volunteer, parent-run, non-profit organization created to benefit HoLa."
        }
        btn={"Meet the team"}
        image={"about-3.jpg"}
        link={"/friends"}
        background={"#f8f8f8"}
      />
      <AboutGroup
        title={"HoLa Alumni"}
        content={
          "HoLa graduates are admitted to some of the best public and private high schools in the region. We are proud of the legacy HoLa is building for our community and our students."
        }
        btn={"Learn more"}
        image={"about-4.jpg"}
        link={"/alumni"}
        background={"#ffffff"}
      />

      <PoliciesSection />

      <HomeSubscribe />
    </div>
  );
}
