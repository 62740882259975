import "./Layout.css";
import Nav from "../Nav";
import Footer from "../Footer";
import Menu from "../Menu";
import { Route, Routes, useLocation } from "react-router-dom";
import { useScroll, useMotionValueEvent } from "framer-motion";
import {
  Academics,
  Admins,
  Admissions,
  Alumni,
  Apply,
  Careers,
  Donate,
  Friends,
  Home,
  News,
  Store,
  StudentLife,
  Trustees,
  Waitlist,
  ContactUs,
  About,
} from "../../screens";
import { ResetScroll } from "../../components";
import { useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import { useAppState } from "../../utils/appState";

export default function Layout() {
  const [{ menu }, setAppState] = useAppState();
  const [showNav, setShowNav] = useState(false);
  const scrollContainer = useRef();
  const location = useLocation();
  const { scrollY } = useScroll({
    container: scrollContainer,
    layoutEffect: false,
  });

  useMotionValueEvent(scrollY, "change", (latest) => {
    // console.log("Page scroll: ", scrollY.getVelocity());
    const velocity = scrollY.getVelocity();
    const containerHeight = scrollContainer?.current?.clientHeight || 0;

    if (velocity > 0 || latest < containerHeight) {
      setShowNav(false);
    } else if (latest > containerHeight && velocity < -100) {
      setShowNav(true);
    }
  });
  return (
    <div className="Layout" ref={scrollContainer} id="scroll-container">
      <ResetScroll />
      <div className="Layout__nav" style={showNav ? { top: 0 } : {}}>
        <Nav />
      </div>

      {isMobile && (
        <div className={"Layout__menu "} style={menu ? { right: 0 } : {}}>
          <Menu />
        </div>
      )}

      {/* <Nav scrollContainer={scrollContainer} /> */}

      <Routes location={location} key={location.key}>
        <Route path="/" element={<Home scrollContainer={scrollContainer} />} />
        <Route path="/academics" element={<Academics />} />
        <Route path="/admins" element={<Admins />} />
        <Route path="/admissions" element={<Admissions />} />
        <Route path="/alumni" element={<Alumni />} />
        {/* <Route path="/apply" element={<Apply />} /> */}
        {/* <Route path="/waitlist" element={<Waitlist />} /> */}
        <Route path="/careers" element={<Careers />} />
        <Route path="/donate" element={<Donate />} />
        <Route path="/friends" element={<Friends />} />
        <Route path="/news" element={<News />} />
        <Route path="/store" element={<Store />} />
        <Route path="/life" element={<StudentLife />} />
        <Route path="/trustees" element={<Trustees />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route
          path="/about"
          element={<About scrollContainer={scrollContainer} />}
        />
      </Routes>

      <Footer />
    </div>
  );
}
