import "./ContactUs.css";
import backgroundImage from "../../images/student-life-hero.jpg";
import Nav from "../../layout/Nav";
import { Hero, PageCaption } from "../../components";
import { useState } from "react";
import { CREATE_ENQUIRY } from "../../api";
import { useMutation } from "@apollo/client";
import { usePageTracking } from "../../utils/usePageTracking";

export default function ContactUs() {
  usePageTracking({
    title: "Contact Us",
  });

  const [fullname, setFullname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  const [mutation, { loading }] = useMutation(CREATE_ENQUIRY, {
    onError: (e) => {
      console.log(e);
      setError(
        "Something went wrong. Please check your internet connection and try again."
      );
    },
    update: (cache, { data: { createEnquiry } }) => {
      setFullname("");
      setEmail("");
      setPhone("");
      setMessage("");
      setError(
        "Your message was sent successfully. A representative will be in touch shortly."
      );
    },
  });

  return (
    <div className="ContactUs">
      <Nav />
      <Hero image={backgroundImage} text={"Contact Us"} />
      <div className="ContactUs__section app-grid">
        <form
          className="ContactUs__form"
          onSubmit={(e) => {
            e.preventDefault();

            setError("");

            if (loading) {
              return;
            }

            if (!fullname || !email || !message) {
              setError("Please fill in all required fields.");
              return;
            }

            mutation({
              variables: {
                data: {
                  fullname,
                  email,
                  phone,
                  message,
                },
              },
            });
          }}
        >
          <div className="ContactUs__form__section">
            <div className="ContactUs__form__section__title">
              Your Information
            </div>

            <div className="ContactUs__form__row">
              <div className="ContactUs__form__row__group">
                <div className="ContactUs__form__row__group__label">
                  Fullname*
                </div>

                <div className="ContactUs__form__row__group__input">
                  <input
                    type="text"
                    value={fullname}
                    onChange={(e) => {
                      setFullname(e.target.value);
                    }}
                  />
                </div>
              </div>

              <div className="ContactUs__form__row__group">
                <div className="ContactUs__form__row__group__label">Email*</div>

                <div className="ContactUs__form__row__group__input">
                  <input
                    type="text"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  />
                </div>
              </div>

              <div className="ContactUs__form__row__group">
                <div className="ContactUs__form__row__group__label">Phone</div>

                <div className="ContactUs__form__row__group__input">
                  <input
                    type="text"
                    value={phone}
                    onChange={(e) => {
                      setPhone(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="ContactUs__form__section">
            <div className="ContactUs__form__section__title">Your Message</div>

            <div className="ContactUs__form__row__group">
              <div className="ContactUs__form__row__group__input">
                <textarea
                  value={message}
                  onChange={(e) => {
                    setMessage(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>

          <div className="ContactUs__form__footer">
            <button
              type="submit"
              className={
                "ContactUs__form__submit " + (loading ? "disabled" : "")
              }
            >
              Submit
            </button>

            {error && <div className="ContactUs__form__error">{error}</div>}
          </div>
        </form>
      </div>
    </div>
  );
}
